import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`12:00 EMOM of:`}</p>
    <p>{`1-Push Press`}</p>
    <p>{`1-Push Jerk`}</p>
    <p>{`1-Split Jerk`}</p>
    <p><em parentName="p">{`*`}{`using 85% 1RM of Push Press`}</em></p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`15/10 Calorie Assault Bike`}</p>
    <p>{`15 Thrusters (95/65)`}</p>
    <p>{`15/10 Calorie Row`}</p>
    <p>{`15 Pullups`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      